import React from 'react';
import { View, Text, Modal, Pressable } from 'react-native';
import useStyles from './styles.css';
import OTPForm from "./otp-form";

const RevokeDialog = (props: any) => {
  const styles = useStyles();

  const handleClosePress = () => {
    props.setVisible(false);
  };

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType="none"
      onRequestClose={handleClosePress}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <View style={styles.modal_container}>
        {/* <Pressable style={styles.btn_mask} onPress={handleClosePress} /> */}
          <View style={styles.modal_body}>
            <OTPForm generated={props.generated} setGenerateOTP={props.setGenerateOTP}></OTPForm>
        </View>
      </View>
    </Modal>
  );
};

export default RevokeDialog;
