import { API_MAYA_PK_SK_BASE64, API_MAYA_SK_BASE64, API_MAGPIE_SK_BASE64} from '@env';
import * as Types from '@src/ducks/types';
import * as api from './request';

export const login = (params: Types.LoginInput) =>
  api.callPost('/auth/login', params);

// FACE ID Login
export const loginFaceID = (params: Types.LoginInput) =>
  api.callPost("/auth/face-login", params, "NO_TOKEN");

export const refreshToken = (params: Types.SessionValue) =>
  api.callPost('/auth/refresh-token', params);

export const forgotpassword = (params: Types.LoginInput) =>
  api.callPost('/auth/forgot-password', params);

export const resetpassword = (params: Types.ResetPasswordInput) =>
  api.callPut('/auth/reset-password', params);

export const register = (params: any) =>
  api.callFormData('/auth/register', params);

export const userInfo = (params: Types.SessionValue) =>
  api.callGet('/user/' + params.id, params.accessToken);

export const signsecureVerifyUser = (document_id: string, accessToken: string) =>
  api.callPost("/directory/signsecure/verify-user/" + document_id, {}, accessToken);

export const signsecureViewDocument = (
  document_id: string,
  accessToken: string
) => api.callPost('/directory/signsecure/view/' + document_id, {}, accessToken);

export const signsecureCreateDocument = (params: any, accessToken: string) =>
  api.callFormData('/directory/signsecure/create', params, accessToken);

export const signsecureDownloadDocument = (
  document_id: string,
  accessToken: string
) => api.callGet('/directory/proxy/' + document_id, accessToken, 'blob');

export const signsecureSignDocument = (
  document_id: string,
  params: any,
  accessToken: string
) =>
  api.callFormData(
    '/directory/signsecure/sign/' + document_id,
    params,
    accessToken
  );

export const signsecureCompleteDocument = (
  document_id: string,
  params: any,
  accessToken: string
) =>
  api.callFormData(
    '/directory/signsecure/complete/' + document_id,
    params,
    accessToken
  );

export const signsecureSignCompleteDocument = (
  document_id: string,
  params: any,
  accessToken: string
) =>
  api.callFormData(
    '/directory/signsecure/sign-complete/' + document_id,
    params,
    accessToken
  );

export const signatureDefault = (accessToken: string) =>
  api.callGet("/signature/default", accessToken);

export const downloadSignature = (signatureId: string, accessToken: string) =>
  api.callGet('/signature/proxy/' + signatureId, accessToken, 'blob');

// UPLOAD SIGNATURE
export const createSignature = (params: any, accessToken: string) =>
  api.callFormData('/signature', params, accessToken);

export const generateOTP = (params: any) =>
  api.callPost('/user/generate-otp', {}, params.accessToken);

export const validateOTP = (params: any) =>
  api.callGet('/user/validate-otp?otp=' + params.otp, params.accessToken);

export const getIpInfo = () => api.callIpInfo();

// GENERATE MAYA TOKEN
export const generateMayaToken = (params: {card: Types.CardDetails}) =>
  api.callMayaPost("/payment-tokens", params, API_MAYA_PK_SK_BASE64);

// CREATE MAYA CUSTOMER
export const createMayaCustomer = (params: any) =>
  api.callMayaPost("/customers", params, API_MAYA_SK_BASE64);

// Link tokenized card to the customer
export const linkGeneratedMayaTokenToCustomer = (params: Types.LinkMayaTokenToCustomer) =>
  api.callMayaPost(`/customers/${params.customerId}/cards`, params, API_MAYA_SK_BASE64);

// PAY THE TRANSACTION - MAYA
export const paymayaTransaction = (customerId: string, cardToken: string, params: Types.MAYATransaction) =>
  api.callMayaPost(`/customers/${customerId}/cards/${cardToken}/payments`, params, API_MAYA_SK_BASE64);

// RETRIEVE CARD DETAILS FROM MAYA
export const getMayaCardDetails = (customerId: string, cardToken: string) =>
  api.callMayaGet(`/customers/${customerId}/cards/${cardToken}`, API_MAYA_SK_BASE64);

// DELETE CARD DETAILS FROM MAYA
export const deleteMayaCardDetails = (customerId: string, cardToken: string) =>
  api.callMayaDelete(`/customers/${customerId}/cards/${cardToken}`, API_MAYA_SK_BASE64);

export const support = (params: any) =>
  api.callPost('/user/support', {}, params.accessToken);


export const addDigitalNotarialBook = (
  params: any,
  accessToken: string
) => api.callPost('/digitalnotarialbook', params, accessToken);

export const fetchNotaryDetails = (
  notaryId: string,
  accessToken: string
) => api.callGet(`/digitalnotarialbook/notary-public?notaryUserId=${notaryId}`, accessToken);

// CHECK FACE ID Registered
export const registerFaceID = (params: any) =>
  api.callPost("/auth/face-id", params);


// CREATE SESSION MAGPIE
export const createSessionMagPie = (params: any) =>
  api.callMagPiePost("/sessions", params, API_MAGPIE_SK_BASE64);

// GET SESSION ID MAGPIE
export const getSessionMagPie = (sessionId: string) =>
  api.callMagPieGet(`/sessions/${sessionId}`, API_MAGPIE_SK_BASE64);

export const signsecureMagpiePayment = (params: any, accessToken: string) =>
  api.callPost('/directory/signsecure/magpie/payment', params, accessToken);