import { SagaIterator } from "@redux-saga/core";
import {
  DocumentFormDataParser,
} from "@src/utils/formdata-helper";
import * as pdfjsLib from 'pdfjs-dist';
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js';
// HELPER
import {
  affixSignature4Contactless,
  completeSignatureWithCreateManifest,
  submitOnlyAffixSignature,
} from "@src/utils/signature-helper";

// API
import {
  addDigitalNotarialBook,
  createMayaCustomer,
  fetchNotaryDetails,
  generateMayaToken,
  getIpInfo,
  linkGeneratedMayaTokenToCustomer,
  paymayaTransaction,
  signsecureCompleteDocument,
  signsecureCreateDocument,
  signsecureDownloadDocument,
  signsecureSignDocument,
  signsecureVerifyUser,
  signsecureViewDocument,
  createSessionMagPie,
  getSessionMagPie
} from "@src/utils/api";

// Slice
import { WEB_BASE_URL } from "@env";
import { checkPartiesSigned } from "@src/utils/filter-helper";
import { generateAttorneyDetails, generateNotaryPublicDetails, generateSealStamp, generateWitnessDetails } from '@src/utils/transform-helper';
import _ from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import { authActions, selectedAuthAccessToken, selectedAuthLoginType, selectedAuthRole } from "../slices/auth.slice";
import { selectedDocumentParties, selectedViewDocument, signsecureActions } from "../slices/signsecure.slice";
import { selectUserDetails, userActions } from "../slices/user.slice";
import { CreateLiveRequestValue } from "../types";

function* handleCreateLiveRequest(action: {
  type: typeof signsecureActions.createLiveRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const body = yield call(DocumentFormDataParser, action.payload, ip);
    const document = yield call(signsecureCreateDocument, body, accessToken);
    console.log('documentdocument123', document)
    
    const base64 = yield call(
      signsecureDownloadDocument,
      document?.data?.id || document?.data?._id,
      accessToken
    );

    yield put(signsecureActions.viewDocumentSuccess({...document.data, uri: base64}));
    // const userDetails = yield select(selectUserDetails);

    // const customerParams = {
    //   "contact": {
    //     "phone": userDetails.phoneNumber,
    //     "email": userDetails.email
    //   },
    //   "firstName": userDetails.firstName,
    //   "middleName": userDetails.middleName,
    //   "lastName": userDetails.lastName,
    //   "customerSince": moment().format("YYYY-MM-DD"),
    // };
    // const customer = yield call(createMayaCustomer, customerParams);
    // const token = yield call(generateMayaToken, {card: action.payload.card });
    // const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
    //   customerId: customer.id,
    //   isDefault: true,
    //   paymentTokenId: token.paymentTokenId
    // });
    // const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
    //   totalAmount: {
    //     amount: action.payload.cost,
    //     currency: "PHP"
    //   },
    //   redirectUrl: {
    //     success: `${WEB_BASE_URL}/contactless/payment-success`,
    //     failure: `${WEB_BASE_URL}/contactless/payment-failed`,
    //   },
    //   requestReferenceNumber: document.id,
    //   metadata: {
    //     documentID: document.id,
    //     user: {
    //       id: userDetails.id,
    //       email: userDetails.email,
    //       firstName: userDetails.firstName,
    //       lastName: userDetails.lastName,
    //       mayaCustomerId: customer.id,
    //       mayaCardtokenId: cardToken.cardTokenId,
    //     },
    //     subMerchantRequestReferenceNumber: "",
    //   },
    // });

    // window.location.href = transaction.verificationUrl;
    
    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleCreateContactlessRequest(action: {
  type: typeof signsecureActions.createContactlessRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const loginType = yield select(selectedAuthLoginType);
    const currentParty = yield select(selectedDocumentParties);
    const uri = yield call(affixSignature4Contactless,
      action.payload.uri,
      action.payload,
      currentParty
    );
    const body = yield call(DocumentFormDataParser, 
      {...action.payload, loginType, uri: `data:application/pdf;base64,${uri}`}, ip);
    const result = yield call(signsecureCreateDocument, body, accessToken);
    yield put(signsecureActions.viewDocumentSuccess({...result.data, uri: `data:application/pdf;base64,${uri}`}));
    
    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleContactlessPayment(action: {
  type: typeof signsecureActions.contactlessPaymentRequest;
  payload: any;
}): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const userDetails = yield select(selectUserDetails);

    const session = yield call(createSessionMagPie, {
      currency: "php",
      payment_method_types: [
         'card'
      ],
      line_items: [
          {
              name: "NotarizeIT",
              quantity: 1,
              // amount: action.payload.cost,
              amount: Math.round(action.payload.cost * 100),
              currency: "php",
              // image: "https://picsum.photos/280/320?random=1"
          }
      ],
      client_reference_id: document.id,
      metadata: {
        id: userDetails.id,
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        documentID: document.id
      },
      success_url: `${WEB_BASE_URL}/contactless/payment-success`,
      cancel_url: `${WEB_BASE_URL}/contactless/payment-failed`,
      // success_url: `http://localhost:19006/contactless/payment-success`,
      // cancel_url: `http://localhost:19006/contactless/payment-failed`
    });

    const getSession = yield call(getSessionMagPie, session.id);

    window.location.href = getSession.payment_url;
    yield put(signsecureActions.contactlessPaymentSuccess());
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signsecureActions.createLiveRequestFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestFailed({}));
  }
}

// function* handleContactlessPayment(action: {
//   type: typeof signsecureActions.contactlessPaymentRequest;
//   payload: any;
// }): SagaIterator {
//   try {
//     const document = yield select(selectedViewDocument);
//     const userDetails = yield select(selectUserDetails);

//     const customerParams = {
//       "contact": {
//         "phone": userDetails.phoneNumber,
//         "email": userDetails.email
//       },
//       "firstName": userDetails.firstName,
//       "middleName": userDetails.middleName,
//       "lastName": userDetails.lastName,
//       "customerSince": moment().format("YYYY-MM-DD"),
//     };
//     const customer = yield call(createMayaCustomer, customerParams);
//     const token = yield call(generateMayaToken, {card: action.payload.card });
//     const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
//       customerId: customer.id,
//       isDefault: true,
//       paymentTokenId: token.paymentTokenId
//     });
//     const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
//       totalAmount: {
//         amount: action.payload.cost,
//         currency: "PHP"
//       },
//       redirectUrl: {
//         success: `${WEB_BASE_URL}/contactless/payment-success`,
//         failure: `${WEB_BASE_URL}/contactless/payment-failed`,
//       },
//       requestReferenceNumber: document.id,
//       metadata: {
//         documentID: document.id,
//         user: {
//           id: userDetails.id,
//           email: userDetails.email,
//           firstName: userDetails.firstName,
//           lastName: userDetails.lastName,
//           mayaCustomerId: customer.id,
//           mayaCardtokenId: cardToken.cardTokenId,
//         },
//         subMerchantRequestReferenceNumber: "",
//       },
//     });

//     window.location.href = transaction.verificationUrl;
//     yield put(signsecureActions.contactlessPaymentSuccess());
//   } catch (error: any) {
//     const message = error.message || error.error || "Something went wrong";
//     const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

//     yield put(signsecureActions.createLiveRequestFailed({ message: failedMessage }));
//     yield delay(1000);
//     yield put(signsecureActions.createLiveRequestFailed({}));
//   }
// }

const getPDFDimensionsFromBase64 = async (base64String: any) => {
  const base64 = base64String.split(',')[1];

  const binaryString = atob(base64);

  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const pdf = await pdfjsLib.getDocument({ data: bytes }).promise;

  const dimensions = [];

  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1 });

    dimensions.push({
      page: pageNum,
      width: viewport.width,
      height: viewport.height,
    });
  }

  return dimensions;
};

function* handleViewDocument(): SagaIterator {
  try {
    const role = yield select(selectedAuthRole);
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);

    if(_.isEmpty(document)){
      yield put(signsecureActions.viewDocumentFailed({}));
      return;
    }

    yield call(
      signsecureVerifyUser,
      document.id || document._id,
      accessToken
    );

    const result = yield call(
      signsecureViewDocument,
      document.id || document._id,
      accessToken
    );
    const base64 = yield call(
      signsecureDownloadDocument,
      document.id || document._id,
      accessToken
    );

    const notaryParty = result.data?.workflow?.parties?.find((party: any) => party.roleType === 'Notary');
    let notaryPublicDetails = {} as any;

    if(role === "notary" && !notaryParty?.status){
      notaryPublicDetails = yield call(
        fetchNotaryDetails,
        notaryParty?.id,
        accessToken
      );
    }

    if(role === "notary" && !notaryParty?.status && document.requestType === 'Live') { // Fix the issue where the stamp still doesn't show unexpectedly.
      const pdfDimensions = yield call(getPDFDimensionsFromBase64, base64);
      notaryPublicDetails = yield call(
        fetchNotaryDetails,
        notaryParty?.id,
        accessToken
      );
      const signatures: any = result.data.workflow.signatures;

      const { notary, docNo, pageNo, bookNo } = notaryPublicDetails.data || {}

      const attorneyDetails = [
        `Atty. ${notary?.firstName} ${notary?.lastName}`,
        `Notary Public for and in Bacolod City`,
        'Until 31 December 2025',
        `Appointment No. ${notary?.appointmentNo}`,
        `Roll No. ${notary?.rollNo}`,
        `PTR No. ${notary?.ptrNo} / ${moment(notary?.ptrNoDate).format("DD MMM YYYY")} / Bacolod City`,
        `Life IBP No. ${notary?.ibpNo} / ${moment(notary?.ibpNoDate).format("DD MMM YYYY")} / Bacolod`,
        `${notary?.regularPlaceOfBusiness}`,
      ]
      const DOC_STAMP = [
        generateWitnessDetails(pdfDimensions[0]),
        generateNotaryPublicDetails({ ...pdfDimensions[0], docNo, pageNo, bookNo, year: moment().format("YYYY")}),
        generateAttorneyDetails(pdfDimensions[0], attorneyDetails),
        generateSealStamp(pdfDimensions[0]),
      ]

      signatures[signatures.length - 1] = signatures[signatures.length - 1].concat(DOC_STAMP);
    }
    
    yield put(signsecureActions.viewDocumentSuccess({ 
      ...result.data, 
      uri: base64, 
      notaryPublicDetails: notaryPublicDetails.data 
    }));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.viewDocumentFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.viewDocumentFailed({}));
  }
}

function* handleAffixSignature(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);
    const currentParty = yield select(selectedDocumentParties);

    const base64 = yield call(submitOnlyAffixSignature,
      document.uri,
      document,
      currentParty
    );

    const ip = yield call(getIpInfo);

    // Create a Blob from the Uint8Array
    const blob = new Blob([base64], { type: "application/pdf" });
    const signature = document.signature || {};
    // Append the Blob to the FormData
    const formData = new FormData();
    formData.append("file", blob, `${document.name}.pdf`);
    formData.append("ip", ip);
    formData.append("loginType", loginType);
    formData.append("timezone", momentTz.tz.guess());
    formData.append("signatureType", signature.signatureType);
    formData.append("signatureId", signature.signatureId);

    const result = yield call(
      signsecureSignDocument,
      document.id,
      formData,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);

    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        result.data
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );

      const notarialBook = yield call(
        addDigitalNotarialBook,
        {
          fees: 0,
          documentId: result.data.id,
          placeOfNotarization: "Online",
          typeOfNotarialAct: result.data.notarialAct,
          competentEvidenceOfIdentity: result.data.requestType === 'Live' ? completed.data.governmentIssuedIdType : completed.data.governmentIssuedIdForPrincipal
        },
        accessToken
      );
      
      yield put(signsecureActions.affixSignatureSuccess(completed.data));
      return;
    }

    yield put(signsecureActions.affixSignatureSuccess(result.data));
  } catch (e: any) {
    console.log("Error", e);
    const message = e.message || e.error || "Something went wrong";
    yield put(signsecureActions.affixSignatureFailed({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

function* refreshDocumentWorker(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);

    if(_.isEmpty(document)){
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id,
      accessToken
    );

    const base64 = yield call(
      signsecureDownloadDocument,
      document.id,
      accessToken
    );
    const signatures = document.workflow.signatures;
    const payload = { ...result.data, uri: base64 };
    payload.signature = document?.signature;
    payload.workflow.signatures = signatures;
    payload.notaryPublicDetails = document.notaryPublicDetails;

    yield put(signsecureActions.updateSignatures(payload));
  } catch (error) { /* empty */ }
}

// Watcher Saga
function* signsecureWatcherSaga(): SagaIterator {
  yield takeEvery(signsecureActions.createLiveRequest.type, handleCreateLiveRequest);
  yield takeEvery(signsecureActions.createContactlessRequest.type, handleCreateContactlessRequest);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleViewDocument);
  yield takeEvery(signsecureActions.affixSignatureRequest.type, handleAffixSignature);
  yield takeLatest(signsecureActions.contactlessPaymentRequest.type, handleContactlessPayment);
  yield takeLatest(signsecureActions.liveSessionRefreshStatus.type, refreshDocumentWorker);
}

export default signsecureWatcherSaga;
